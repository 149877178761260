import React from 'react'
import getConfig from 'next/config'
import nookies from 'nookies'

import SvgAlert from '@ntuctech/devex-tangram/Icon/SvgAlert'
import SvgSuccess from '@ntuctech/devex-tangram/Icon/SvgSuccess'
import { get, patch, headers } from '../../../lib/fetch'
import { AUTH_TOKEN_KEY } from '../../../utils/constants'

const {
  publicRuntimeConfig: { STRUDEL_API },
} = getConfig()

const { [AUTH_TOKEN_KEY]: authToken } = nookies.get() || {}

export const initialState = {
  activeData: [],
  pastData: [],
  error: '',
  loading: true,
  toastData: {
    show: false,
    message: '',
    type: '',
    icon: '',
  },
  activeTab: 0,
  voucherDetails: {},
  showQrCode: false,
  showHowToUse: false,
  imgLoading: true,
  showFilter: false,
  activeFilter: { 0: 'All' },
  filteredData: [],
}

export const reducer = (state, action) => {
  let icon = <SvgSuccess />
  if (action.toastType === 'alert') {
    icon = <SvgAlert />
  }
  switch (action.type) {
    case 'SET_ACTIVE_DATA':
      return {
        ...state,
        activeData: action.value,
        loading: false,
        filteredData: action.filteredData,
      }
    case 'SET_PAST_DATA':
      return {
        ...state,
        pastData: action.value,
        loading: false,
        filteredData: action.filteredData,
      }
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.value,
        imgLoading: true,
      }
    case 'SET_ACTIVE_FILTER_VALUE':
      return {
        ...state,
        activeFilter: action.filteredTag,
        filteredData: action.filteredData,
        showFilter: false,
      }
    case 'SHOW_QR_CODE':
      return {
        ...state,
        showQrCode: true,
        imgLoading: true,
      }
    case 'BACK_TO_VOUCHER_DETAILS':
      return {
        ...state,
        showQrCode: false,
        voucherDetails: action.value,
        imgLoading: false,
      }
    case 'BACK_TO_QRCODE_SCREEN':
      return {
        ...state,
        showQrCode: true,
        voucherDetails: action.value,
        showHowToUse: false,
      }
    case 'SET_ERROR':
      return { ...state, error: action.value }
    case 'TOGGLE_FILTER':
      return { ...state, showFilter: !state.showFilter }
    case 'SET_IMAGE_LOADING':
      return { ...state, imgLoading: true }
    case 'IMAGE_LOADED':
      return { ...state, imgLoading: false }
    case 'LOADING':
      return { ...state, loading: true }
    case 'SHOW_VOUCHER_DETAILS':
      return { ...state, voucherDetails: action.value }
    case 'SHOW_HOWTOUSE_DETAILS':
      return { ...state, showHowToUse: true, imgLoading: true }
    case 'SHOW_TOAST':
      return {
        ...state,
        toastData: {
          show: true,
          message: action.message,
          type: action.toastType,
          icon: icon,
        },
      }
    case 'HIDE_VOUCHER_DETAILS':
      return {
        ...state,
        voucherDetails: {},
        showQrCode: false,
        showHowToUse: false,
        imgLoading: false,
      }
    case 'HIDE_TOAST':
      return {
        ...state,
        toastData: {
          show: false,
        },
      }
    default:
      return state
  }
}

const fetchWalletDetails = (status, variantKey = '') => {
  return get(
    `${STRUDEL_API}/me/wallet?experiments=${variantKey}&status=${status}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'X-User-Source': 'fpon_web',
      },
    }
  )
}

const fetchWalletDetailsforPDP = (
  status,
  storeId,
  productId,
  variantKey,
  categoryIds
) => {
  return get(
    `${STRUDEL_API}/me/wallet?status=${status}&storeId=${storeId}&productId=${productId}&experiments=${variantKey}&categoryIds=${categoryIds}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'X-User-Source': 'fpon_web',
      },
    }
  )
}

export const fetchWalletDataPDP = async (
  status,
  storeId,
  { dispatch },
  clientItemId,
  variantKey,
  categoryIds = ''
) => {
  try {
    const results = await fetchWalletDetailsforPDP(
      status,
      storeId,
      clientItemId,
      variantKey,
      categoryIds
    )
    if (status === 'active') {
      dispatch({
        type: 'SET_ACTIVE_DATA',
        value: results,
        filteredData: [],
      })
    }
  } catch (e) {
    dispatch({ type: 'SET_ERROR', value: e })
  }
}
export const fetchWalletData = async (
  status,
  activeFilter,
  { dispatch },
  variantKey
) => {
  try {
    const results = await fetchWalletDetails(status, variantKey)
    let filteredData = []
    if (parseInt(Object.keys(activeFilter)[0]) > 0) {
      filteredData = results.list?.filter(voucher =>
        voucher.tags.find(value => value === Object.values(activeFilter)[0])
      )
    }
    if (status === 'active') {
      dispatch({
        type: 'SET_ACTIVE_DATA',
        value: results,
        filteredData,
      })
    } else {
      dispatch({
        type: 'SET_PAST_DATA',
        value: results,
        filteredData,
      })
    }
  } catch (e) {
    dispatch({ type: 'SET_ERROR', value: e })
  }
}
export const storeVoucherCode = async (
  activeFilter,
  isVoucher,
  { code, dispatch }
) => {
  try {
    const reqJson = { rewardType: isVoucher ? 'offer' : 'voucher', code }
    const result = await patch(`${STRUDEL_API}/me/wallet`, {
      body: JSON.stringify(reqJson),
      headers: headers(),
    })
    if (result) {
      dispatch({
        type: 'SHOW_TOAST',
        message: 'Successfully stored!',
        toastType: 'success',
      })
    }
  } catch (err) {
    dispatch({
      type: 'SHOW_TOAST',
      message: err?.code ? err.message : err,
      toastType: 'alert',
    })
  }
  fetchWalletData('active', activeFilter, { dispatch })
}

export const getWalletDetails = code => {
  return get(`${STRUDEL_API}/me/rewards/${code}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      'X-User-Source': 'fpon_web',
    },
  })
}
