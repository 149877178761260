import React from 'react'
import styled from 'styled-components'

import { SCREEN_SIZE } from '../../lib/Media'

export const TabContentSC = styled.div`
  width: 100%;
  margin: 0;
  border-radius: 0.25rem;
  border: 0.0625rem solid #eaeaea;
  min-height: 40.625rem;
  background: #ffffff;
  padding: 1rem 0.75rem;

  .trust-banner {
    padding: 0;
  }

  ${SCREEN_SIZE.Below.MobileL} {
    border: none;
    border-radius: 0;
    padding: 1rem;

    &.fpOnAccount__tab--color-bg {
      background-color: #f4f5f7;
    }
    .trust-banner {
      padding: 0;
    }
  }

  & .ndsGridItem {
    padding: 0 0.75rem;
  }

  ${SCREEN_SIZE.From.Tablet} {
    padding: 2.5rem 2.75rem;

    & .fponAccount__form-content {
      max-width: 22rem;
    }
    .trust-banner {
      padding: 0 0.75rem;
    }
  }
`

export const ProfileCard = styled(TabContentSC)`
  min-height: initial;
  margin-bottom: 1rem;

  & .fpOnAccount__profile--data {
    display: flex;

    ${SCREEN_SIZE.Below.MobileL} {
      flex-wrap: wrap;
    }
  }

  ${SCREEN_SIZE.Below.MobileL} {
    background-color: #ffffff;
    border-radius: 0.25rem;
    border: 0.0625rem solid #eaeaea;
    padding: 1.5rem;

    & .ProfileDisplayName {
      margin-top: 0;
    }

    & .LuckyDrawIllust {
      top: -1.5rem;
      right: -1.5rem;
    }
  }
`

const TabContent = ({ children, ...rest }) => {
  return <TabContentSC {...rest}>{children}</TabContentSC>
}

export default TabContent
