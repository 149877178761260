import React from 'react'
import styled from 'styled-components'

import Grid from '@ntuctech/devex-tangram/Grid'
import Typography from '@ntuctech/devex-tangram/Typography'
import SvgBack from '@ntuctech/devex-tangram/Icon/SvgBack'

const TabContentTitleSC = styled.div`
  width: 100%;
  padding: 0 0 1rem;
  font-size: 1rem;
  border-bottom: 0.0625rem solid #eaeaea;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`

const BackButtonSC = styled(SvgBack)`
  cursor: pointer;
  margin-right: 1rem;
  min-width: 1.5rem;
`

const BackButton = ({ onClick }) => (
  <BackButtonSC data-testid="BackButton" onClick={onClick} />
)

const TabContentTitle = ({
  title,
  mobileVisible,
  mobileTitleSize,
  backAction,
  ...rest
}) => (
  <Grid item className="ndsGridItem" {...rest}>
    <TabContentTitleSC className="ndsHidden-xs fpOnAccount__tab-content--title">
      {backAction && <BackButton onClick={backAction} />}
      <Typography variant="h3">{title}</Typography>
    </TabContentTitleSC>
    {mobileVisible && (
      <TabContentTitleSC className="ndsHidden-s-up fpOnAccount__tab-content--title">
        {backAction && <BackButton onClick={backAction} />}
        <Typography variant={mobileTitleSize || 'h6'}>{title}</Typography>
      </TabContentTitleSC>
    )}
  </Grid>
)

export default TabContentTitle
