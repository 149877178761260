import React, { useState } from 'react'
import SC from './elements'
const TransactionHistorySection = ({ transactions, isSideSheet }) => {
  const [showSeeAll, setShowSeeAll] = useState(false)
  const [limit, setLimit] = useState(3)
  const lineCount = transactions.length
  const show = lineCount > 3
  return (
    <SC.Box mx={isSideSheet ? 0 : 3} xs-pb={5}>
      <SC.Typography variant="h5">Transaction history</SC.Typography>
      {transactions.length < 1 && (
        <SC.Typography variant="body2">No transactions found.</SC.Typography>
      )}
      {transactions.slice(0, limit).map((transaction, i) => (
        <SC.TransactionItem
          key={`trans-${i}`}
          data-last-item={
            transactions.length - 1 === i || (i === 2 && !showSeeAll)
          }
          data-testid="transaction-item"
          data-see-all={i === 2 && transactions.length > 3 && !showSeeAll}
        >
          <div className="date">{transaction.date}</div>
          <div className="row">
            <div className="store-name">{transaction.storeName}</div>
            <div className="amount">{transaction.amount}</div>
          </div>
        </SC.TransactionItem>
      ))}
      {show && (
        <SC.StyledLink
          transactions
          size="m"
          target="_blank"
          rel="nofollow"
          color="#1557BF"
          weight="bold"
          onClick={() => {
            setShowSeeAll(showSeeAll ? false : true)
            setLimit(showSeeAll ? 3 : transactions.length)
          }}
        >
          {showSeeAll ? 'See less' : 'See all'}
        </SC.StyledLink>
      )}
    </SC.Box>
  )
}

export default TransactionHistorySection
