const debug = require('debug')

const logError = debug('error')
const logInfo = debug('info')
const logWarning = debug('warning')
const logSuccess = debug('success')
const logAuth0Activities = debug('Auth0')

// set this namespace to log via console.log
// so it goes to stdout instead of stderr
// eslint-disable-next-line no-console
logAuth0Activities.log = console.log.bind(console)

module.exports = {
  logError,
  logInfo,
  logWarning,
  logSuccess,
  logAuth0Activities,
}
